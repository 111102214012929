import { useState, useEffect } from "react";
import { FixedTabs, FixedTab } from "./styles";
import { Tab, Nav, Container, Row, Col, Button } from "react-bootstrap";
import Home from "../Tabs/home";
import Results from "../Tabs/results";
import Stories from "../Tabs/stories";


const isBrowser = typeof window !== "undefined"

const FixedNavbar = () => {


  //distance to scroll before nav reaches maxNavOpacity
  const maxScrollDistance = 200;

  //how opaque should the nav background be at its maximum
  const maxNavOpacity = 1;

  const [navBackgroundOpacity, setNavBackgroundOpacity] = useState(0);
  const [scrollYAmount, setScrollYAmount] = useState(isBrowser ? window.scrollY : 0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {

    //set background opacity based on scroll distance
    const updateNavOpacity = (e) => {
      const neweNavBackgroundOpacity = (
        window.scrollY < maxScrollDistance ?
          window.scrollY / maxScrollDistance * maxNavOpacity :
          maxNavOpacity
      );
      setNavBackgroundOpacity(neweNavBackgroundOpacity);
    }

    window.addEventListener("scroll", updateNavOpacity);

    return () => {
      window.removeEventListener("scroll", updateNavOpacity);
    }

  }, []);

  useEffect(() => {

    //set background opacity based on scroll distance
    const updateNavDirection = (e) => {

      const moving = window.scrollY;

      //always show nav on iOS overscroll
      setVisible(scrollYAmount > moving || moving < 0 || scrollYAmount < 0);
      setScrollYAmount(moving);

    }

    window.addEventListener("scroll", updateNavDirection);

    return (() => {
      window.removeEventListener("scroll", updateNavDirection);
    });

  });

  const directionClass = visible ? "up" : "down";



  const handleTabSelect = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  const tabs = [
    {
      key: "details",
      label: "Dates & Details",
      content: <Home />
    },
    {
      key: "success",
      label: "Nov Success",
      content: <Results />
    },
    // {
    //   key: "graduates",
    //   label: "Graduates",
    //   content: "asdf"
    // },
    // {
    //   key: "stories",
    //   label: "Stories",
    //   content: <Stories />
    // },
  ];


  return (
    <div>

      <Tab.Container defaultActiveKey="details" onSelect={handleTabSelect}
      >

        <FixedTabs className={`w-100 ${directionClass}`} $opacity={navBackgroundOpacity} >
          <Container fluid>
            <Row className="justify-content-between py-3 align-items-center">
              <Col xs={6} lg={2}>
                <a href="https://keeneland.com/sales"
                  className="nav-branding py-2 d-flex justify-content-start ms-lg-5"
                >
                  <img src="img/icons/logo-mobile.svg" alt="Keeneland Logo" className="d-md-none" />
                  <img src="img/icons/logo.svg" alt="Keeneland Logo" className="d-none d-md-block" />
                </a>
              </Col>
              <Col xs={12} lg={8} className="order-2 order-lg-0 px-0">
                <Nav
                  className="justify-content-center"
                  fill
                >
                  {
                    tabs.map((tab, i) => {
                      return <Nav.Link eventKey={tab.key} key={`nav-tab-${tab.key}`}><span>{tab.label}</span></Nav.Link>
                    })
                  }
                </Nav>
              </Col>
              <Col xs={6} lg={2} className="justify-content-end d-flex align-items-start">
                {/* <Button className="me-lg-5" variant="primary" href="https://www.keeneland.com/sales">Learn More</Button> */}
                <Button className="me-lg-5" variant="primary" href="https://www.keeneland.com/sales">Learn More</Button>
              </Col>

            </Row>
          </Container>
        </FixedTabs>

        <Tab.Content>
          {
            tabs.map((tab, i) => {
              return <Tab.Pane eventKey={tab.key} key={`tab-content-${tab.key}`}>{tab.content}</Tab.Pane>
            })
          }
        </Tab.Content>

      </Tab.Container>
    </div>


  );

}

export default FixedNavbar;